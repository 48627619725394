var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.getInputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getValue),expression:"getValue"}],staticClass:"form-control max-w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus:ring-blue-500 focus:border-blue-500 rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brand focus:outline-none -mr-2 sm:-mr-0 w-full border-box h-10",class:{
    'border-box-stroke': !_vm.error,
    'border-red-600': _vm.error,
  },attrs:{"placeholder":_vm.getPlaceHolder,"disabled":_vm.disabled || _vm.formAlreadySubmitted,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.getValue)?_vm._i(_vm.getValue,null)>-1:(_vm.getValue)},on:{"change":function($event){var $$a=_vm.getValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.getValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.getValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.getValue=$$c}}}}):((_vm.getInputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getValue),expression:"getValue"}],staticClass:"form-control max-w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus:ring-blue-500 focus:border-blue-500 rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brand focus:outline-none -mr-2 sm:-mr-0 w-full border-box h-10",class:{
    'border-box-stroke': !_vm.error,
    'border-red-600': _vm.error,
  },attrs:{"placeholder":_vm.getPlaceHolder,"disabled":_vm.disabled || _vm.formAlreadySubmitted,"type":"radio"},domProps:{"checked":_vm._q(_vm.getValue,null)},on:{"change":function($event){_vm.getValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.getValue),expression:"getValue"}],staticClass:"form-control max-w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus:ring-blue-500 focus:border-blue-500 rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brand focus:outline-none -mr-2 sm:-mr-0 w-full border-box h-10",class:{
    'border-box-stroke': !_vm.error,
    'border-red-600': _vm.error,
  },attrs:{"placeholder":_vm.getPlaceHolder,"disabled":_vm.disabled || _vm.formAlreadySubmitted,"type":_vm.getInputType},domProps:{"value":(_vm.getValue)},on:{"input":function($event){if($event.target.composing)return;_vm.getValue=$event.target.value}}})
}
var staticRenderFns = []

export { render, staticRenderFns }