<template>
  <input
    class="form-control max-w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid focus:ring-blue-500 focus:border-blue-500 rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brand focus:outline-none -mr-2 sm:-mr-0 w-full border-box h-10"
    :class="{
      'border-box-stroke': !error,
      'border-red-600': error,
    }"
    :placeholder="getPlaceHolder"
    :type="getInputType"
    v-model="getValue"
    :disabled="disabled || formAlreadySubmitted"
  />
</template>

<script>
export default {
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formAlreadySubmitted: {
      type: Boolean,
    }
  },
  computed: {
    getInputType() {
      if (this.fieldData.inputType && this.fieldData.inputType === "phone")
        return "tel";
      else return this.fieldData.inputType || "text";
    },
    getPlaceHolder() {
      return this.fieldData.placeholder || "";
    },
    getValue: {
      get() {
        return this.fieldData.value || "";
      },
      set(newValue) {
        // let data = { value: newValue, field_id: this.fieldData.field_id };
        // if (this.fieldData.multi_id) {
        //     data.multi_id = this.fieldData.multi_id;
        //     delete data.field_id
        // }
        // EventBus.$emit("changeFieldValue", data);
        this.$emit("input", newValue.trim());
        this.$emit("handleFieldValueChnage", newValue.trim());
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}


input{
  height: 44px;
}


@media screen and (max-width:768px){
  input {
    border-radius:0px;
    margin:auto;
    width:100%;
  }
}
</style>
